import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@customink/pigment-react';
import {useMobileBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';
import React from 'react';
import CustomExpandIcon from './CustomExpandIcon';

const ProductDetailsRow = ({
  accordionExpanded,
  accordionSummary,
  accordionWrapperSx = {},
  children,
  childrenRef,
  defaultExpanded = false,
  desktopVersionSx,
  onAccordionClick,
  title,
  useAccordionAlways = false
}) => {
  const isMobile = useMobileBreakpoint();
  const dataTestId = title.toLowerCase().replace(/ /g, '-');

  return isMobile || useAccordionAlways ? (
    <Box
      data-testid={dataTestId}
      ref={childrenRef}
      sx={{
        ...accordionWrapperSx
      }}>
      <Accordion
        disableGutters
        defaultExpanded={defaultExpanded}
        expanded={accordionExpanded}
        onClick={onAccordionClick}
        sx={{
          backgroundColor: '#f4f4f4', // TODO: Add color to Pigment theme (gray light)
          boxShadow: 'none'
        }}>
        <AccordionSummary expandIcon={<CustomExpandIcon />}>
          {React.isValidElement(accordionSummary) ? (
            accordionSummary
          ) : (
            <Typography variant="subHeading" fontWeight={600}>
              {accordionSummary}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  ) : (
    <Box
      sx={{
        margin: '0 auto',
        maxWidth: {lg: '63rem', xl: '80.25rem'},
        padding: '0 1rem',
        width: '100%'
      }}>
      <Box
        data-testid={dataTestId}
        ref={childrenRef}
        sx={{
          fontSize: '0.825rem',
          ...desktopVersionSx
        }}>
        <Typography variant="h6" style={{paddingTop: 0}}>
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};

export default ProductDetailsRow;
