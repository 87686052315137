import {Box, Link, Typography} from '@customink/pigment-react';
import React, {useContext} from 'react';
import addColorToStylePath from '../../utils/addColorToStylePath';
import labLink from '../../utils/labLink';
import CurrentSelectedColorContext from '../Context/colorContext';
import ProductContext from '../Context/productContext';
import ProductDetailsRow from '../ProductDetailsRow';
import ProductDetailsCard from './RelatedProductCard';

const ComplementProducts = ({complementProducts}) => {
  const {currentSelectedColor} = useContext(CurrentSelectedColorContext);
  const {legacyStyleId, name, preferSingles} = useContext(ProductContext);

  return (
    <ProductDetailsRow
      accordionSummary="Coordinating Styles"
      title="Coordinating Styles">
      <Typography variant="bodyLong1" className="complement-heading-body">
        To include these coordinating styles in your order, add them to your
        design in the{' '}
        <Link
          variant="inherit"
          href={labLink(
            currentSelectedColor.legacyColorId,
            preferSingles,
            currentSelectedColor.loadLabCid,
            legacyStyleId
          )}>
          Design Lab
        </Link>
        .
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: {md: '1rem', sm: '0.5rem'},
          gridTemplateColumns: {
            lg: 'repeat(4, 1fr)',
            md: 'repeat(auto-fill, minmax(200px, 1fr))',
            sm: 'repeat(auto-fill, minmax(280px, 1fr))'
          },
          mt: '1rem'
        }}>
        {complementProducts.map((complement) => (
          <ProductDetailsCard
            key={`complement-${complement.name}`}
            complimentBadge={complement.complement_badge}
            imageSrc={complement.image_src}
            imageSrcSet={complement.image_srcset}
            linkHref={addColorToStylePath(complement.link, complement.color)}
            minQty={complement.min_qty}
            name={complement.name}
            ratingCount={complement.rating_count}
            ratingScore={complement.rating_score}
            sizing={complement.sizing}
            onClick={() => {
              CustomInk.Metrics.fireEvent({
                action: 'PDP',
                category: 'complementary styles',
                label: {name}
              });
            }}
          />
        ))}
      </Box>
    </ProductDetailsRow>
  );
};

export default ComplementProducts;
