import {Box, Link, Rating, Typography} from '@customink/pigment-react';
import React, {useContext} from 'react';
import useTrustPilotProductReviews from 'hooks/queries/trustPilotProductReviews';
import CurrentSelectedColorContext from 'product_details_page/Context/colorContext';
import ProductContext from 'product_details_page/Context/productContext';
import {trackReadReviewsClickEvent} from 'utils/metrics/productDetailsMetrics';

export default function ProductHeader({reviewRef}) {
  const {
    colors = [],
    name: styleName,
    ratingCount,
    ratingValue
  } = useContext(ProductContext);
  const skuList = colors.map((color) => color.id);
  const {data: trustPilotProductReviews} = useTrustPilotProductReviews(skuList);
  const {setReviewsAccordionState} = useContext(CurrentSelectedColorContext);

  const scrollToReviews = () => {
    setReviewsAccordionState(true);
    reviewRef.current.scrollIntoView({behavior: 'smooth'});
  };

  const tpReviewCount = trustPilotProductReviews?.numberOfReviews?.total || 0;

  return (
    <Box
      sx={{
        alignItems: {md: 'flex-start', sm: 'center'},
        display: 'flex',
        flexDirection: {md: 'column', sm: 'row'},
        flexWrap: 'wrap'
      }}>
      <Typography
        data-testid="product-header-style-name"
        component="h1"
        variant="h5"
        sx={{
          fontSize: {md: '1.5rem', sm: '1.25rem'},
          lineHeight: '1.25',
          paddingTop: '0'
        }}>
        {styleName}
      </Typography>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          gap: '0.5rem',
          minHeight: '1.5rem'
        }}>
        <Rating
          precision={0.1}
          value={Number(ratingValue)}
          ratingCount={ratingCount}
        />
        {tpReviewCount > 0 && (
          <span>
            <Link
              onClick={() => {
                scrollToReviews();
                trackReadReviewsClickEvent();
              }}
              variant="captionRegular">
              Read Reviews
            </Link>
          </span>
        )}
      </Box>
    </Box>
  );
}
