import React, {forwardRef, useContext} from 'react';
import {withErrorBoundary} from 'react-error-boundary';
import useRelatedStyles from 'hooks/queries/relatedStyles';
import CurrentSelectedColorContext from 'product_details_page/Context/colorContext';
import ProductContext from 'product_details_page/Context/productContext';
import rollbar from 'src/vendor/rollbar';
import AlternativeProducts from './AlternativeProducts';
import ComplementProducts from './ComplementProducts';

const RelatedProducts = forwardRef(function RelatedProducts(_, similarRef) {
  const {currentSelectedColor} = useContext(CurrentSelectedColorContext);
  const {id: styleId} = useContext(ProductContext);
  const {data: relatedProducts, isError} = useRelatedStyles(
    styleId,
    currentSelectedColor?.id,
    {
      enabled: !!currentSelectedColor?.id,
      keepPreviousData: true,
      placeholderData: {
        alternatives: [],
        complements: []
      }
    }
  );

  if (isError) {
    return null;
  }

  return (
    <>
      {relatedProducts?.complements.length > 0 && (
        <ComplementProducts complementProducts={relatedProducts?.complements} />
      )}
      {relatedProducts?.alternatives.length > 0 && (
        <AlternativeProducts
          alternativeProducts={relatedProducts?.alternatives}
          similarRef={similarRef}
        />
      )}
    </>
  );
});

const RelatedProductsWithErrorBoundary = withErrorBoundary(RelatedProducts, {
  fallback: <div>Something went wrong!</div>,
  onError: (error, componentStack) => {
    rollbar.error(error, componentStack);
  }
});

export default RelatedProductsWithErrorBoundary;
